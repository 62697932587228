import {
  COUNT_STAR_ARG,
  CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG,
  CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG,
  CREATE_DISPLAY_TABLE_CONFIG,
  CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG,
  ColumnDisplayFormat,
  DISPLAY_TABLE_DEFAULTS,
  RENAME_COLUMN_PROPERTIES,
  UPDATE_CHART_CELL,
  UPDATE_COLUMN_PROPERTIES,
  UPDATE_DBT_METRIC_CELL,
  UPDATE_DISPLAY_TABLE_CONFIG,
  UPDATE_SQL_CELL,
  mapExploreFieldsToPivotFieldsObject,
} from "@hex/common";

import {
  APPLY_OPERATION,
  QUEUE_OPERATION,
  ReduxAtomicOperationHandler,
} from "../../atomic-operations";
import {
  CellContentsMP,
  hexVersionMPActions,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { HexVersionAOClientContext } from "../HexVersionAOClientContext";
import {
  DbtMetricCellMpFragment,
  DisplayTableCellMpFragment,
  ExploreCellMpFragment,
  PivotCellMpFragment,
  SqlCellMpFragment,
} from "../HexVersionMPModel.generated";

type DisplayTableConfig =
  | DisplayTableCellMpFragment["displayTableConfig"]
  | SqlCellMpFragment["sqlDisplayTableConfig"]
  | DbtMetricCellMpFragment["dbtMetricDisplayTableConfig"]
  | PivotCellMpFragment["displayTableConfig"]
  | ExploreCellMpFragment["displayTableConfig"];

export const getDisplayTableConfigFromCellContents = (
  baseCell: CellContentsMP | null | undefined,
): DisplayTableConfig | null | undefined => {
  if (baseCell?.__typename === "SqlCell") {
    return baseCell.sqlDisplayTableConfig;
  } else if (baseCell?.__typename === "DbtMetricCell") {
    return baseCell.dbtMetricDisplayTableConfig;
  } else if (baseCell?.__typename === "ChartCell") {
    return baseCell.chartDisplayTableConfig;
  } else if (baseCell?.__typename === "DisplayTableCell") {
    return baseCell.displayTableConfig;
  } else if (baseCell?.__typename === "PivotCell") {
    return {
      ...baseCell.displayTableConfig,
      hideIndex: shouldHidePivotIndex(baseCell.pivotConfig),
    };
  } else if (baseCell?.__typename === "ExploreCell") {
    if (baseCell.viewType === "source-table") {
      return baseCell.displayTableConfig;
    } else {
      // Hydrate the display table config with the display formats from the fields
      const formatsByColumn = baseCell.spec.fields.reduce(
        (acc, field) => {
          if (field.displayFormat == null) {
            return acc;
          }

          const columnName = `${field.value}`;
          acc[columnName] = field.displayFormat;

          if (field.aggregation != null) {
            acc[`${columnName}_${field.aggregation.toUpperCase()}`] =
              field.displayFormat;
          }
          return acc;
        },
        {} as Record<string, ColumnDisplayFormat>,
      );

      return {
        ...baseCell.displayTableConfig,
        hideIndex:
          // EXP-1145: pivot tables actually need the index column to show row values
          baseCell.spec.visualizationType === "pivot-table"
            ? shouldHidePivotIndex(
                mapExploreFieldsToPivotFieldsObject(baseCell.spec.fields),
              )
            : baseCell.displayTableConfig.hideIndex,
        columnProperties: baseCell.displayTableConfig.columnProperties.map(
          (cp) => ({
            ...cp,
            displayFormat: formatsByColumn[cp.originalName],
            renameTo:
              cp.renameTo ??
              (cp.originalName === COUNT_STAR_ARG ? "Count of Records" : null),
          }),
        ),
        calcs: null,
      };
    }
  }
};

// Hide empty index column for pivots with no row or column indexes.
const shouldHidePivotIndex = <TPivotField>(pivotConfig: {
  rows: TPivotField[];
  columns: TPivotField[];
  values: TPivotField[];
}): boolean => {
  const { columns, rows, values } = pivotConfig;
  return rows.length === 0 && columns.length === 0 && values.length > 0;
};

const getDisplayTableConfigKeyForCellType = (
  cell: CellContentsMP | null | undefined,
):
  | "sqlDisplayTableConfig"
  | "dbtMetricDisplayTableConfig"
  | "displayTableConfig"
  | "chartDisplayTableConfig" => {
  if (cell?.__typename === "SqlCell") {
    return "sqlDisplayTableConfig";
  } else if (cell?.__typename === "ChartCell") {
    return "chartDisplayTableConfig";
  } else if (cell?.__typename === "DbtMetricCell") {
    return "dbtMetricDisplayTableConfig";
  } else {
    return "displayTableConfig";
  }
};

export const UpdateDisplayTableConfigHandler: ReduxAtomicOperationHandler<
  UPDATE_DISPLAY_TABLE_CONFIG,
  HexVersionAOClientContext
> = {
  applyToStore(store, { payload: { cellId, key, value } }, { hexVersionId }) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);
    if (baseCell == null || displayTableConfig == null) {
      throw new Error(
        "Can't update a display table config that doesn't exist or associated with cell of wrong type",
      );
    }

    const newDisplayTableConfig = {
      ...displayTableConfig,
      [key]: value,
    };

    store.dispatch(
      hexVersionMPActions.setCellContentsField({
        hexVersionId,
        data: {
          cellId,
          key: getDisplayTableConfigKeyForCellType(baseCell),
          value: newDisplayTableConfig,
        },
      }),
    );
  },
  mapToUndo(
    store,
    { payload: { cellId, displayTableConfigId, key } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);
    if (displayTableConfig == null) {
      throw new Error(
        "Can't undo an update a display table config that doesn't exist or associated with cell of wrong type",
      );
    }

    const displayTableConfigValue = displayTableConfig[key];

    return UPDATE_DISPLAY_TABLE_CONFIG.create({
      cellId,
      displayTableConfigId,
      key,
      value: displayTableConfigValue,
    });
  },
  mapToInitialRevision(store, { payload: { cellId } }, { hexVersionId }) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (!baseCell) {
      return QUEUE_OPERATION;
    }

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);
    if (displayTableConfig == null) {
      throw new Error(
        "Can't update a display table config that doesn't exist or associated with cell of wrong type",
      );
    }
    return displayTableConfig.revision;
  },
};

export const CreateDisplayTableConfigHandler: ReduxAtomicOperationHandler<
  CREATE_DISPLAY_TABLE_CONFIG,
  HexVersionAOClientContext
> = {
  applyToStore(
    store,
    { payload: { cellId, displayTableConfigId, filters } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (baseCell == null) {
      throw new Error(
        "Can't add a display table config to a cell that does not exist",
      );
    }
    const newDisplayTableConfig: DisplayTableConfig = {
      ...DISPLAY_TABLE_DEFAULTS,
      __typename: "DisplayTableConfig",
      id: displayTableConfigId,
      columnProperties: [],
      revision: 0,
      sortByColumnDefault: null,
      sortByIndexColumnDefault: null,
      calcs: null,
      conditionalFormatting: null,
      filters: filters ?? null,
      conditionalFormattingReferences: null,
      conditionalFormattingReferencesParseError: null,
      customColumnOrdering: null,
      defaultColumnWidth: null,
      pivotColumnOrdering: null,
      filtersReferences: null,
      filtersReferencesParseError: null,
      pageSize: DISPLAY_TABLE_DEFAULTS.pageSize,
      pinnedColumns: null,
      hiddenColumns: null,
      showAggregations: false,
      columnAggregations: null,
    };

    if (baseCell.__typename === "SqlCell") {
      store.dispatch(
        hexVersionMPActions.setCellContentsField({
          hexVersionId,
          data: {
            cellId,
            key: "sqlDisplayTableConfig",
            value: newDisplayTableConfig,
          },
        }),
      );
    } else if (baseCell.__typename === "DbtMetricCell") {
      store.dispatch(
        hexVersionMPActions.setCellContentsField({
          hexVersionId,
          data: {
            cellId,
            key: "dbtMetricDisplayTableConfig",
            value: newDisplayTableConfig,
          },
        }),
      );
    } else if (baseCell.__typename === "ChartCell") {
      store.dispatch(
        hexVersionMPActions.setCellContentsField({
          hexVersionId,
          data: {
            cellId,
            key: "chartDisplayTableConfig",
            value: newDisplayTableConfig,
          },
        }),
      );
    } else if (
      baseCell.__typename === "DisplayTableCell" ||
      baseCell.__typename === "PivotCell"
    ) {
      // noop as display table config is already created for these cell types
      // upon cell creation and updating them would be wrong.
    } else {
      throw new Error(
        "Can't add a display table config to a cell of wrong type",
      );
    }
  },
  mapToUndo(store, { payload: { cellId } }, { hexVersionId }) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (baseCell == null) {
      throw new Error(
        "Can't undo adding a display table config to a cell that does not exist",
      );
    }

    if (baseCell.__typename === "SqlCell") {
      return UPDATE_SQL_CELL.create({
        cellId,
        key: "useRichDisplay",
        value: false,
        sqlCellId: baseCell.sqlCellId,
      });
    } else if (baseCell.__typename === "DbtMetricCell") {
      return UPDATE_DBT_METRIC_CELL.create({
        cellId,
        key: "useRichDisplay",
        value: false,
        dbtMetricCellId: baseCell.dbtMetricCellId,
      });
    } else if (baseCell.__typename === "ChartCell") {
      return UPDATE_CHART_CELL.create({
        cellId,
        chartCellId: baseCell.chartCellId,
        key: "chartDisplayTableConfig",
        value: null,
      });
    } else {
      throw new Error(
        "Can't add a display table config to a cell of wrong type",
      );
    }
  },
  mapToInitialRevision(store, { payload: { cellId } }, { hexVersionId }) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (!baseCell) {
      return QUEUE_OPERATION;
    }

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);

    if (!displayTableConfig) {
      return APPLY_OPERATION;
    }

    return displayTableConfig.revision;
  },
};

export const CreateSqlCellDisplayTableConfigHandler: ReduxAtomicOperationHandler<
  CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG,
  HexVersionAOClientContext
> = {
  applyToStore(
    store,
    { payload: { cellId, displayTableConfigId, pageSize } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);
    if (baseCell == null) {
      throw new Error(
        "Can't add a display table config to a cell that does not exist",
      );
    }
    const newDisplayTableConfig: DisplayTableConfig = {
      ...DISPLAY_TABLE_DEFAULTS,
      __typename: "DisplayTableConfig",
      id: displayTableConfigId,
      columnProperties: [],
      revision: 0,
      sortByColumnDefault: null,
      sortByIndexColumnDefault: null,
      calcs: null,
      conditionalFormatting: null,
      filters: null,
      conditionalFormattingReferences: null,
      conditionalFormattingReferencesParseError: null,
      customColumnOrdering: null,
      defaultColumnWidth: null,
      pivotColumnOrdering: null,
      filtersReferences: null,
      filtersReferencesParseError: null,
      pageSize: pageSize ?? DISPLAY_TABLE_DEFAULTS.pageSize,
      pinnedColumns: null,
      hiddenColumns: null,
      showAggregations: false,
      columnAggregations: null,
    };

    store.dispatch(
      hexVersionMPActions.setCellContentsField({
        hexVersionId,
        data: {
          cellId,
          key: "sqlDisplayTableConfig",
          value: newDisplayTableConfig,
        },
      }),
    );
  },
  mapToUndo(store, { payload: { cellId, sqlCellId } }) {
    return UPDATE_SQL_CELL.create({
      cellId,
      key: "useRichDisplay",
      value: false,
      sqlCellId,
    });
  },
  mapToInitialRevision(store, { payload: { cellId } }, { hexVersionId }) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (!baseCell) {
      return QUEUE_OPERATION;
    }

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);

    if (!displayTableConfig) {
      return APPLY_OPERATION;
    }

    return displayTableConfig.revision;
  },
};

export const CreateDbtMetricCellDisplayTableConfigHandler: ReduxAtomicOperationHandler<
  CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG,
  HexVersionAOClientContext
> = {
  applyToStore(
    store,
    { payload: { cellId, displayTableConfigId } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);
    if (baseCell == null) {
      throw new Error(
        "Can't add a display table config to a cell that does not exist",
      );
    }
    const newDisplayTableConfig = {
      id: displayTableConfigId,
      columnProperties: [],
    };

    store.dispatch(
      hexVersionMPActions.setCellContentsField({
        hexVersionId,
        data: {
          cellId,
          key: "dbtMetricDisplayTableConfig",
          value: newDisplayTableConfig,
        },
      }),
    );
  },
  mapToUndo(store, { payload: { cellId, dbtMetricCellId } }) {
    return UPDATE_DBT_METRIC_CELL.create({
      cellId,
      key: "useRichDisplay",
      value: false,
      dbtMetricCellId,
    });
  },
  mapToInitialRevision(store, { payload: { cellId } }, { hexVersionId }) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (!baseCell) {
      return QUEUE_OPERATION;
    }

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);

    if (!displayTableConfig) {
      return APPLY_OPERATION;
    }

    return displayTableConfig.revision;
  },
};

export const CreateChartCellDisplayTableConfigHandler: ReduxAtomicOperationHandler<
  CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG,
  HexVersionAOClientContext
> = {
  applyToStore(
    store,
    { payload: { cellId, displayTableConfigId } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);
    if (baseCell == null) {
      throw new Error(
        "Can't add a display table config to a cell that does not exist",
      );
    }
    const newDisplayTableConfig: DisplayTableConfig = {
      ...DISPLAY_TABLE_DEFAULTS,
      __typename: "DisplayTableConfig",
      id: displayTableConfigId,
      columnProperties: [],
      revision: 0,
      sortByColumnDefault: null,
      sortByIndexColumnDefault: null,
      calcs: null,
      conditionalFormatting: null,
      filters: null,
      conditionalFormattingReferences: null,
      conditionalFormattingReferencesParseError: null,
      customColumnOrdering: null,
      defaultColumnWidth: null,
      pivotColumnOrdering: null,
      filtersReferences: null,
      filtersReferencesParseError: null,
      pageSize: DISPLAY_TABLE_DEFAULTS.pageSize,
      pinnedColumns: null,
      hiddenColumns: null,
      showAggregations: false,
      columnAggregations: null,
    };

    store.dispatch(
      hexVersionMPActions.setCellContentsField({
        hexVersionId,
        data: {
          cellId,
          key: "chartDisplayTableConfig",
          value: newDisplayTableConfig,
        },
      }),
    );
  },
  mapToUndo(store, { payload: { cellId, chartCellId } }) {
    return UPDATE_CHART_CELL.create({
      cellId,
      chartCellId,
      key: "chartDisplayTableConfig",
      value: null,
    });
  },
  mapToInitialRevision(store, { payload: { cellId } }, { hexVersionId }) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (!baseCell) {
      return QUEUE_OPERATION;
    }

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);

    if (!displayTableConfig) {
      return APPLY_OPERATION;
    }

    return displayTableConfig.revision;
  },
};

export const UpdateColumnPropertiesHandler: ReduxAtomicOperationHandler<
  UPDATE_COLUMN_PROPERTIES,
  HexVersionAOClientContext
> = {
  applyToStore(
    store,
    { payload: { cellId, key, originalName, value } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (
      !baseCell ||
      (!("displayTableConfig" in baseCell) &&
        !("sqlDisplayTableConfig" in baseCell) &&
        !("dbtMetricDisplayTableConfig" in baseCell) &&
        !("chartDisplayTableConfig" in baseCell))
    ) {
      throw new Error(
        "Can't update a display table config that doesn't exist or of wrong type",
      );
    }

    store.dispatch(
      hexVersionMPActions.upsertColumnPropertyForDisplayTable({
        hexVersionId,
        data: {
          cellId,
          originalName,
          key,
          value,
        },
      }),
    );
  },
  mapToUndo(
    store,
    { payload: { cellId, displayTableConfigId, key, originalName } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);
    if (displayTableConfig == null) {
      throw new Error(
        "Can't update a display table config that doesn't exist or associated with cell of wrong type",
      );
    }
    const previousProperties = displayTableConfig.columnProperties.find(
      (p) => p.originalName === originalName,
    );

    return UPDATE_COLUMN_PROPERTIES.create({
      displayTableConfigId,
      cellId,
      originalName,
      key,
      value: previousProperties != null ? previousProperties[key] : null,
    });
  },
  mapToInitialRevision(
    store,
    { payload: { cellId, originalName } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (!baseCell) {
      return QUEUE_OPERATION;
    }

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);
    if (displayTableConfig == null) {
      throw new Error(
        "Can't update a display table config that doesn't exist or associated with cell of wrong type",
      );
    }
    const rename = displayTableConfig.columnProperties.find(
      (p) => p.originalName === originalName,
    );

    return rename?.revision ?? APPLY_OPERATION;
  },
};

export const RenameColumnPropertiesHandler: ReduxAtomicOperationHandler<
  RENAME_COLUMN_PROPERTIES,
  HexVersionAOClientContext
> = {
  applyToStore(
    store,
    { payload: { cellId, newName, originalName } },
    { hexVersionId },
  ) {
    store.dispatch(
      hexVersionMPActions.renameColumnPropertyForDisplayTable({
        hexVersionId,
        data: {
          cellId,
          originalName,
          newName,
        },
      }),
    );
  },
  mapToUndo(
    _store,
    { payload: { cellId, displayTableConfigId, newName, originalName } },
  ) {
    return RENAME_COLUMN_PROPERTIES.create({
      displayTableConfigId,
      cellId,
      originalName: newName,
      newName: originalName,
    });
  },
  mapToInitialRevision(
    store,
    { payload: { cellId, originalName } },
    { hexVersionId },
  ) {
    const baseCell = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(store.getState(), cellId);

    if (!baseCell) {
      return QUEUE_OPERATION;
    }

    const displayTableConfig = getDisplayTableConfigFromCellContents(baseCell);
    if (displayTableConfig == null) {
      throw new Error(
        "Can't update a display table config that doesn't exist or associated with cell of wrong type",
      );
    }
    const rename = displayTableConfig.columnProperties.find(
      (p) => p.originalName === originalName,
    );

    return rename?.revision ?? APPLY_OPERATION;
  },
};
